<div class="home-container">
  <div class="buttons-container">
    <div class="filter-container">
      <label>{{ "HOME.FILTER" | translate }}</label>
      <input (keyup)="sendFilterQuery($event);">
    </div>
    <div class="selection-buttons">
      <div class="dataset-button-container">
        <button class="dataset-button" mat-raised-button color="primary" (click)="toggleMenuDatasets()">
          <span>{{ 'HOME.DATASETS' | translate }}</span>
          <i class="fa-solid fa-angle-down"></i>
        </button>
      </div>
      @if (isVisibleDatasets) {
        <div class="menu-datasets" *ngIf="isVisibleDatasets">
          @for (dataset of filteredDataSets; track dataset) {
            <div class="dataset-button-container">
              <i class="fa-solid fa fa-cog" style="cursor: pointer" (click)="navigateToAdminRoute(dataset.name)"
                 matTooltip="Admin view"></i>
              <button class="dataset-button" mat-raised-button color="primary"
                      (click)="navigateToDataset(dataset.name)">
                <span>{{ 'HOME.' + dataset.name | translate }}</span>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
            <div class="dataset-button-container">
              <i class="fa-solid fa fa-cog" style="cursor: pointer" (click)="navigateToAdminRoute('test')"
                 matTooltip="Admin view"></i>
              <button class="dataset-button" mat-raised-button color="primary" (click)="navigateToAdminRoute('test')">
                <span>{{ 'HOME.HOUSEHOLD' | translate }}</span>
                <i class="fa-solid fa-angle-right"></i>
              </button>
            </div>
          }
        </div>
      }
      <div class="dataset-button-container">
        <button class="dataset-button" mat-raised-button color="primary" (click)="toggleMenuManagement()">
          <span>{{ 'HOME.DATA_MANAGEMENT' | translate }}</span>
          <i class="fa-solid fa-angle-down"></i>
        </button>
      </div>
      @if(isVisibleManagement) {
        <div class="menu-data-management">
          <div class="first-row">
            <button mat-raised-button class="dataset-button" (click)="navigateToUploadModule()">
              <div class="button-label-container">
                <i class="fa fa-upload"></i>
                <span>{{ 'HOME.UPLOAD_MODULE' | translate }}</span>
              </div>
            </button>
            <button mat-raised-button class="dataset-button" (click)="navigateToDownloadModule()">
              <div class="button-label-container">
                <i class="fa fa-download"></i>
                <span>{{ 'HOME.DOWNLOAD_MODULE' | translate }}</span>
              </div>
            </button>
          </div>
          <div class="second-row">
            <button mat-raised-button class="dataset-button" (click)="navigateToAdminRoute('test')">
              <span>{{ 'HOME.CODES' | translate }}</span>
            </button>
            <button mat-raised-button class="dataset-button" (click)="navigateToAdminRoute('test')">
              <span>{{ 'HOME.REGIONS' | translate }}</span>
            </button>
            <button mat-raised-button class="dataset-button" (click)="navigateToAdminRoute('test')">
              <span>{{ 'HOME.CONTACTS' | translate }}</span>
            </button>
          </div>

        </div>
      }
      <div class="dataset-button-container">
        <button class="dataset-button" mat-raised-button color="primary" (click)="toggleMenuExternal()">
          <span>{{ 'HOME.EXTERNAL' | translate }}</span>
          <i class="fa-solid fa-angle-down"></i>
        </button>
      </div>
      @if(isVisibleExternal) {
        <div class="menu-external">
          <button class="dataset-button" (click)="navigateToAdminRoute('test')">ICT Prices</button>
        </div>
      }
    </div>
  </div>
  <div class="selection-buttons admin-view">
    <div class="dataset-button-container">
      <button class="" mat-raised-button color="primary" (click)="navigateToAdminRoute('admin')">
        <span>{{ 'HOME.ADMIN' | translate }}</span>
        <i class="fa-solid fa-solid fa fa-cog"></i>
      </button>
    </div>
  </div>
  @if (loading) {
    <app-loader>{{ 'HOME.LOADING_OPTIONS' | translate }}</app-loader>
  }
</div>

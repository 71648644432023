import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  CellHistoryModel,
  CellInfoModel,
  cellUpdatePayloadModel,
  ColumnsModel,
  CountryYearsInfoModel,
  DataModel,
  getIdPayload,
} from './models/update-country-table-models';
import { ModifyOneRowComponent } from '../dialogues/modify-one-row/modify-one-row.component';
import { ProviderService } from '../../core/provider.service';
import { CellStatus } from './enums/enums';
import { MatPaginator } from '@angular/material/paginator';
import { CountriesListsModel } from './models/country-filters-model';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { MatSort } from '@angular/material/sort';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import {
  ConditionalFormattingComponent,
  ConditionalFormattingOperation,
} from '../dialogues/conditional-formatting/conditional-formatting.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-update-country-table',
  templateUrl: './update-country-table.component.html',
  styleUrls: ['./update-country-table.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class UpdateCountryTableComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @ViewChild(MatTable)
  table!: MatTable<any>;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  @ViewChild(MatTable, { read: ElementRef })
  matTableRef!: ElementRef;
  @ViewChild('formElement', { read: ElementRef })
  formRef!: ElementRef;
  columns: ColumnsModel[] = [];
  stickyColumns = ['actions', 'description', 'countryName', 'code'];
  displayedColumns: string[] = [];
  tableDataSource = new MatTableDataSource<any>();
  countriesDataBE: DataModel[] = [];
  form: FormGroup = {} as FormGroup;
  countryFiltersFormGroup = new FormGroup({
    country: new FormControl(''),
    code: new FormControl(''),
    description: new FormControl(''),
  });
  countries: CountriesListsModel[] = [{ isoCode: '', shortName: 'No Filter' }];
  yearValueChanged = false;
  loading = false;
  startYear = 2010;
  endYear = 2023;
  yearsArray: number[] = [];
  tableDataCount = 0;
  descriptionFilterEntered = '';
  codeFilterEntered = '';
  cellInfo?: CellInfoModel;
  cellHistory: CellHistoryModel[] = [];
  cellInfoLoading = false;
  cellInfoPresent = false;
  previousCell: any;
  selectedCell: any;
  previousColumn: any;
  selectedColumn: any;
  showTableError = false;
  selectedFileName = '';
  checkboxFormGroup = new FormGroup({
    published: new FormControl(false),
    estimated: new FormControl(false),
  });
  filteredCountries: CountriesListsModel[] = [];
  resizing = false;
  conditionThresholdValue = '';
  conditionOperation!: ConditionalFormattingOperation;
  conditions: any[] = [];
  isConditionalFormattingFilterOn = false;
  downloadTableDataLoader = false;
  private currentCell?: CountryYearsInfoModel;
  private internalNote?: string;
  private note?: string;
  private modifiedCountryFE = {} as FormGroup;
  private columnUnderModification = '';
  private source?: string;

  constructor(
    private formBuilder: FormBuilder,
    private _formBuilder: FormBuilder,
    private providerService: ProviderService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super();
  }
  ngOnInit(): void {
    this.initializeTableData();
    this.getCountriesList();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.countryFiltersFormGroup.controls.country.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(200))
        .subscribe((countryCode) => {
          this._filter(countryCode);
        })
    );
    this.subscriptions.add(
      this.countryFiltersFormGroup.controls.code.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => {
          this.codeFilterEntered = value ?? '';
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.countryFiltersFormGroup.controls.description.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => {
          this.descriptionFilterEntered = value ?? '';
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData();
        }
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData();
      })
    );
  }

  displayFn(value: any): string {
    return value
      ? this.filteredCountries.find((country) => country.isoCode === value)
          ?.shortName ?? ''
      : 'No filter';
  }

  async getCellInfo(cellFormGroup: FormGroup, year: string): Promise<void> {
    if (
      this.selectedCell !== this.previousCell ||
      this.selectedColumn !== this.previousColumn
    ) {
      this.cellInfoPresent = true;
      const cellData = this.findDataForHistory(cellFormGroup, year);
      if (cellData.length > 0 && cellData[0].length > 0) {
        this.getCellHistory(cellData[0][0].id);
        this.cellInfoLoading = true;
        try {
          this.currentCell = cellData[0][0];
          this.cellInfo =
            await this.providerService.countryTableService.getCellInfo(
              cellData[0][0].id
            );
        } catch (e) {
          console.log(e);
        } finally {
          this.cellInfoLoading = false;
        }
        this.checkboxFormGroup.controls.estimated.setValue(
          this.cellInfo?.estimated ?? false
        );
        this.checkboxFormGroup.controls.published.setValue(
          this.cellInfo?.published ?? false
        );
      } else {
        this.currentCell = {
          id: '',
          year: year,
          value: '',
        };
        this.checkboxFormGroup.controls.estimated.setValue(false);
        this.checkboxFormGroup.controls.published.setValue(false);
        this.cellInfo = {
          internalNotes: '',
          publishNotes: '',
          published: false,
          estimated: false,
          source: '',
          sourceId: '',
        };
        this.cellHistory = [];
        this.cellInfoPresent = false;
      }
    }
  }

  async deleteSource(
    cellIsEmpty: boolean,
    sourceValue?: string
  ): Promise<void> {
    const deleteSourceByUser =
      sourceValue !== undefined && sourceValue.length === 0 && !cellIsEmpty;
    if (
      deleteSourceByUser &&
      this.cellInfo &&
      this.cellInfo.sourceId !== null
    ) {
      this.cellInfoLoading = true;
      const sourceId = [this.cellInfo?.sourceId];
      await this.providerService.countryTableService.deleteSourceById(sourceId);
      this.cellInfoLoading = false;
    }
    if (cellIsEmpty && this.cellInfo && this.cellInfo.sourceId !== null) {
      const sourceId = [this.cellInfo?.sourceId];
      await this.providerService.countryTableService.deleteSourceById(sourceId);
    }
  }

  async getCellHistory(cellId: string): Promise<void> {
    this.cellInfoLoading = true;
    try {
      this.cellHistory = (
        await this.providerService.countryTableService.getCellHistory(cellId)
      ).sort((one, two) => two.version - one.version);
      this.cellHistory.map((el) => {
        el.lastModifiedOn = this.providerService.utilService.humanReadableDate(
          el.lastModifiedOn,
          true
        );
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.cellInfoLoading = false;
    }
  }

  buildFormGroupsArrayDynamicallyUsingBEData(): void {
    if (this.countriesDataBE.length !== 0) {
      this.form = this.formBuilder.group({
        formGroups: this.formBuilder.array(
          this.countriesDataBE.map((BEdata: DataModel) => {
            const group = this.formBuilder.group({});
            group.addControl('code', new FormControl(BEdata.code));
            group.addControl(
              'description',
              new FormControl(BEdata.description)
            );
            group.addControl('codeId', new FormControl(BEdata.codeId));
            group.addControl('countryId', new FormControl(BEdata.countryId));
            group.addControl(
              'countryName',
              new FormControl(BEdata.countryName)
            );
            for (const year of BEdata.years) {
              group.addControl('id-' + year.year, new FormControl(year.id));
              group.addControl(
                year.year,
                new FormControl(
                  year.value !== null
                    ? this.changeValueVisualization(year.value ?? '')
                    : '',
                  [Validators.pattern(/^[0-9,.]+$/)]
                )
              );
            }
            const yearsToAdd = this.yearsArray.filter(
              (value) =>
                !BEdata.years
                  .map((year) => year.year)
                  .includes(value.toString())
            );
            for (const notFoundYear of yearsToAdd) {
              group.addControl('id-' + notFoundYear, new FormControl(null));
              group.addControl(
                notFoundYear.toString(),
                new FormControl(null, [Validators.pattern(/^[0-9,.]+$/)])
              );
            }
            return group;
          })
        ),
      });
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setColumnSizeOnResize(this.matTableRef.nativeElement.clientWidth);
  }
  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target !== null) {
      this.selectedFileName =
        target.files && target.files.length > 0 ? target.files[0].name : '';
    }
  }
  changeValueVisualization(cellValue: string): string {
    let newString = '';
    const beforeDot = cellValue.toString().split('.')[0];
    const afterDot = cellValue.toString().split('.')[1];
    newString =
      beforeDot.length > 3
        ? beforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
          (afterDot !== undefined ? `.${afterDot}` : '.00')
        : cellValue;
    return newString.toString();
  }
  buildArrayForYears(startYear: number, endYear: number): void {
    const array: number[] = [];
    for (let i = startYear; i <= endYear; i++) {
      array.push(i);
    }
    this.yearsArray = array;
  }

  async getCountriesList(): Promise<void> {
    try {
      this.countries = this.countries.concat(
        await this.providerService.countryTableService.getCountriesList()
      );
      this.countryFiltersFormGroup.controls.country.setValue('', {
        emitEvent: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  setDisplayedColumns(): void {
    this.displayedColumns = [];
    new Set(this.columns.map((value) => value.field)).forEach((c) => {
      this.displayedColumns.push(c);
    });
    this.displayedColumns =
      this.providerService.countryTableService.reorderColumns(
        this.displayedColumns
      );
  }

  fillTableColumns(): void {
    this.columns = [
      {
        title: 'Actions',
        field: 'actions',
        width: 50,
        sortable: false,
        readOnly: true,
        status: CellStatus.running,
      },
    ];
    Object.keys(this.countriesDataBE[0]).map((k: any) => {
      switch (k) {
        case 'code': {
          this.columns.push({
            title: 'Code',
            field: k,
            width: 50,
            sortable: false,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        case 'description': {
          this.columns.push({
            title: 'Description',
            field: k,
            width: 800,
            sortable: false,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        case 'countryName': {
          this.columns.push({
            title: 'Country Name',
            field: k,
            width: 50,
            sortable: true,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        case 'countryId': {
          this.columns.push({
            title: 'Country Id',
            field: k,
            width: 800,
            sortable: false,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        case 'codeId': {
          this.columns.push({
            title: 'Code Id',
            field: k,
            width: 80,
            sortable: false,
            readOnly: true,
            status: CellStatus.running,
          });
          break;
        }
        default: {
          if (k === 'years') {
            this.yearsArray.forEach((y) => {
              this.columns.push({
                title: y.toString(),
                field: y.toString(),
                width: 300,
                sortable: false,
                readOnly: true,
                status: CellStatus.running,
              });
            });
          }
          break;
        }
      }
    });
  }

  async initializeTableData(): Promise<void> {
    this.loading = true;
    this.showTableError = false;
    const filters = {
      codeFilter: this.countryFiltersFormGroup.controls.code.value,
      countryCodeFilter: this.countryFiltersFormGroup.controls.country.value,
      codeDescriptionFilter:
        this.countryFiltersFormGroup.controls.description.value,
    };
    try {
      this.countriesDataBE =
        await this.providerService.countryTableService.getTableData(
          this.startYear,
          this.endYear,
          'code',
          btoa(JSON.stringify(filters)),
          'ASC',
          0,
          20
        );
      if (this.countriesDataBE.length !== 0) {
        this.buildArrayForYears(this.startYear, this.endYear);
        this.fillTableColumns();
        this.setDisplayedColumns();
        this.form = this._formBuilder.group({
          formGroups: this._formBuilder.array([]),
        });
        this.buildFormGroupsArrayDynamicallyUsingBEData();
        this.tableDataSource.data = (
          this.form.get('formGroups') as FormArray
        ).controls;
      }
      await this.getTableCount();
      this.setFormScroll();
    } catch (error) {
      this.showTableError = true;
    }
    this.loading = false;
  }

  async getTableCount(): Promise<void> {
    const filters = {
      codeFilter: this.countryFiltersFormGroup.controls.code.value,
      countryCodeFilter: this.countryFiltersFormGroup.controls.country.value,
      codeDescriptionFilter:
        this.countryFiltersFormGroup.controls.description.value,
    };
    try {
      this.tableDataCount =
        await this.providerService.countryTableService.tableCount(
          this.startYear,
          this.endYear,
          btoa(JSON.stringify(filters))
        );
    } catch (error) {
      console.log(error);
    }
  }

  async updateCell(
    modifiedCountryFE: FormGroup,
    columnUnderModification: string
  ): Promise<void> {
    this.yearValueChanged = true;
    this.modifiedCountryFE = modifiedCountryFE;
    this.columnUnderModification = columnUnderModification;

    if (modifiedCountryFE.controls[columnUnderModification].valid) {
      const isCellIdNull =
        this.modifiedCountryFE.controls[`id-${this.columnUnderModification}`]
          .value === null;
      const unmodifiedCountryBE = this.findUnmodifiedData();
      const modifiedValueFE =
        this.modifiedCountryFE.controls[this.columnUnderModification].value;
      const canInsert = unmodifiedCountryBE.length === 0;
      const canUpdateCell =
        modifiedValueFE !== null &&
        modifiedValueFE.length >= 0 &&
        this.checkIfYearValueChanged(
          modifiedCountryFE,
          columnUnderModification,
          unmodifiedCountryBE
        );
      const isFEValueEmpty =
        modifiedCountryFE.controls[columnUnderModification].value === '';
      const isBEValueNull =
        unmodifiedCountryBE.length !== 0
          ? unmodifiedCountryBE[0][0].value === null
          : true;
      if (isCellIdNull && modifiedValueFE && canInsert) {
        const payloadForInsertID = this.preparePayLoadForGettingId();
        const returnedId =
          await this.providerService.countryTableService.insertID(
            payloadForInsertID
          );
        this.modifiedCountryFE.controls[
          `id-${this.columnUnderModification}`
        ].setValue(returnedId);
        modifiedCountryFE.controls[this.columnUnderModification].setValue(
          this.changeValueVisualization(modifiedValueFE)
        );
      } else if (canUpdateCell) {
        try {
          if (isFEValueEmpty && this.cellInfo && isBEValueNull !== null) {
            this.deleteSource(true);
            this.updatePublishedNotes('');
            this.updateInternalNotes('');
            this.estimatedChanged({ checked: false } as MatCheckboxChange);
            this.publishedChanged({ checked: false } as MatCheckboxChange);
          }
          const payload = this.prepareUpdateCellPayload();
          await this.providerService.countryTableService.updateCell(payload);
          this.updateCountriesDataForUser(modifiedValueFE);
        } catch (e: any) {
          // this.providerService.utilService.showMessage(e, LogLevel.error);
        }
      }
    } else {
      const unmodifiedCountryBE = this.findUnmodifiedData();
      if (
        unmodifiedCountryBE &&
        unmodifiedCountryBE.length > 0 &&
        unmodifiedCountryBE[0][0]
      ) {
        modifiedCountryFE.controls[columnUnderModification].setValue(
          unmodifiedCountryBE[0][0].value,
          { emitEvent: false }
        );
      }
    }
  }

  getCellIds(selectedRow: FormArray): string[] {
    return Object.keys(selectedRow.controls).map((k: any) => {
      if (k.includes('id')) {
        return selectedRow.controls[k].value;
      }
    });
  }

  prepareUpdateCellPayload(): cellUpdatePayloadModel {
    const isFEValueEmpty =
      this.modifiedCountryFE.controls[this.columnUnderModification].value
        .length === 0;
    const FEValue =
      this.modifiedCountryFE.controls[this.columnUnderModification].value;
    let payload: cellUpdatePayloadModel;
    !isFEValueEmpty
      ? (payload = {
          id: this.modifiedCountryFE.controls[
            `id-${this.columnUnderModification}`
          ].value,
          value: this.removeCommasAndDots(FEValue),
        })
      : (payload = {
          id: this.modifiedCountryFE.controls[
            `id-${this.columnUnderModification}`
          ].value,
        });
    return payload;
  }
  updateCountriesDataForUser(modifiedValueFE: string): void {
    const unmodifiedBE = this.findUnmodifiedData();
    const formattedModifiedValue = this.removeCommasAndDots(modifiedValueFE);
    if (unmodifiedBE) {
      this.countriesDataBE.map((el) => {
        let year = el.years.find((y) => y.id === unmodifiedBE[0][0].id);
        const yearIndex = el.years.findIndex(
          (y) => y.id === unmodifiedBE[0][0].id
        );
        if (year) {
          year = {
            id: year.id,
            year: year.year,
            value: modifiedValueFE !== '' ? formattedModifiedValue : null,
          };
          el.years[yearIndex] = year;
        }
      });
      this.modifiedCountryFE.controls[this.columnUnderModification].setValue(
        this.changeValueVisualization(formattedModifiedValue)
      );
    }
  }
  preparePayLoadForGettingId(): getIdPayload {
    return {
      codeId: this.modifiedCountryFE.controls['codeId'].value,
      countryId: this.modifiedCountryFE.controls['countryId'].value,
      year: Number(this.columnUnderModification),
      value: Number(
        this.modifiedCountryFE.controls[this.columnUnderModification].value
      ),
    };
  }

  findDataForHistory(
    modifiedCountryFE: FormGroup,
    columnUnderModification: string
  ): CountryYearsInfoModel[][] {
    return this.countriesDataBE
      .map((el) => {
        return el.years.filter(
          (el) =>
            el.id ===
            modifiedCountryFE.controls[`id-${columnUnderModification}`].value
        );
      })
      .filter((el) => el.length !== 0);
  }

  findUnmodifiedData(): CountryYearsInfoModel[][] {
    return this.countriesDataBE
      .map((el) =>
        el.years.filter(
          (el: any) =>
            el.id ===
            this.modifiedCountryFE.controls[
              `id-${this.columnUnderModification}`
            ].value
        )
      )
      .filter((el) => el.length !== 0);
  }

  checkIfYearValueChanged(
    modifiedCountryFE: FormGroup,
    column: string,
    unmodifiedCountryBE: CountryYearsInfoModel[][]
  ): boolean {
    const valueWithNoCommas = this.removeCommasAndDots(
      modifiedCountryFE.controls[column].value
    );
    if (
      modifiedCountryFE.controls[column].value.length === 0 &&
      unmodifiedCountryBE[0][0].value === null
    ) {
      return false;
    }
    if (
      modifiedCountryFE.controls[column].value.length === 0 &&
      unmodifiedCountryBE[0][0].value !== null
    ) {
      return true;
    } else {
      return unmodifiedCountryBE[0] &&
        unmodifiedCountryBE[0].length !== 0 &&
        modifiedCountryFE.controls[column].value !== ''
        ? Number(unmodifiedCountryBE[0][0].value) !== Number(valueWithNoCommas)
        : false;
    }
  }

  removeCommasAndDots(numberString: string): string {
    return numberString.replace(/,|\.00$/g, '').trim();
  }

  setColumnSizeOnResize(tableWidth: number): void {
    this.providerService.countryTableService.setTableResize(
      this.columns,
      this.displayedColumns,
      tableWidth,
      'mat-column-'
    );
  }

  openModificationDialog(e: FormArray): void {
    this.providerService.utilService.openDialog(ModifyOneRowComponent, {
      data: e,
      cellIds: this.getCellIds(e),
      columns: this.columns,
      callback: () => {
        this.getTableData();
      },
    });
  }

  openConditionalFormattingDialog(): void {
    let config = {};
    if (this.isConditionalFormattingFilterOn) {
      config = this.conditions;
    }
    this.dialog
      .open(
        ConditionalFormattingComponent,
        this.isConditionalFormattingFilterOn ? { data: config } : undefined
      )
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.isConditionalFormattingFilterOn = true;
          for (const res of response) {
            this.conditions.push({
              conditionThresholdValue: res.inputValue,
              conditionOperation: res.conditionOperation,
            });
          }
        }
      });
  }

  checkConditions(value: string): boolean {
    const conditionResults = [];
    const valueNum = parseInt(value);
    for (const condition of this.conditions) {
      const conditionThresholdValue = parseInt(
        condition.conditionThresholdValue
      );
      if (
        condition.conditionOperation === ConditionalFormattingOperation.EQUAL
      ) {
        conditionResults.push(valueNum === conditionThresholdValue);
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.NOT_EQUAL
      ) {
        conditionResults.push(valueNum !== conditionThresholdValue);
      } else if (
        condition.conditionOperation === ConditionalFormattingOperation.GREATER
      ) {
        conditionResults.push(valueNum > conditionThresholdValue);
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.GREATER_OR_EQUAL
      ) {
        conditionResults.push(valueNum >= conditionThresholdValue);
      } else if (
        condition.conditionOperation === ConditionalFormattingOperation.LESS
      ) {
        conditionResults.push(valueNum < conditionThresholdValue);
      } else if (
        condition.conditionOperation ===
        ConditionalFormattingOperation.LESS_OR_EQUAL
      ) {
        conditionResults.push(valueNum <= conditionThresholdValue);
      }
    }
    return (
      value !== '' &&
      value !== undefined &&
      value !== null &&
      conditionResults.every((condition) => condition === true) &&
      this.isConditionalFormattingFilterOn
    );
  }

  clearConditionalFormattingFilter(): void {
    this.isConditionalFormattingFilterOn = false;
  }

  async updateInternalNotes(emptyNote?: string): Promise<void> {
    if (this.currentCell && this.internalNote && emptyNote === undefined) {
      await this.providerService.countryTableService.updateInternalNotes(
        this.currentCell.id,
        this.internalNote
      );
    }
    if (emptyNote !== undefined && this.currentCell) {
      await this.providerService.countryTableService.updateInternalNotes(
        this.currentCell.id,
        emptyNote
      );
    }
  }

  async updatePublishedNotes(emptyNote?: string): Promise<void> {
    if (this.currentCell && this.note && emptyNote === undefined) {
      await this.providerService.countryTableService.updatePublishedNotes(
        this.currentCell.id,
        this.note
      );
    }
    if (emptyNote !== undefined && this.currentCell) {
      await this.providerService.countryTableService.updatePublishedNotes(
        this.currentCell.id,
        emptyNote
      );
    }
  }

  internalNotesSelectionChanged($event: any): void {
    this.internalNote = $event.target.value;
  }

  notesSelectionChanged($event: any): void {
    this.note = $event.target.value;
  }

  sourceSelectionChanged($event: any): void {
    this.source = $event.target.value;
  }

  async updateSource(): Promise<void> {
    if (this.currentCell && this.source && this.source.length > 0) {
      await this.providerService.countryTableService.updateSource(
        this.currentCell.id,
        this.source,
        this.cellInfo?.sourceId
      );
    }
  }

  async publishedChanged($event: MatCheckboxChange): Promise<void> {
    if (this.currentCell) {
      await this.providerService.countryTableService.updatePublished(
        this.currentCell.id,
        $event.checked
      );
      this.checkboxFormGroup.controls.published.setValue($event.checked);
    }
  }

  async estimatedChanged($event: MatCheckboxChange): Promise<void> {
    if (this.currentCell) {
      await this.providerService.countryTableService.updateEstimated(
        this.currentCell.id,
        $event.checked
      );
      this.checkboxFormGroup.controls.estimated.setValue($event.checked);
    }
  }

  setSelectedCell(element: any, column: any): void {
    this.previousCell = this.selectedCell;
    this.previousColumn = this.selectedColumn;
    this.selectedCell = element;
    this.selectedColumn = column;
  }

  async getTableData(): Promise<void> {
    this.loading = true;
    this.showTableError = false;
    try {
      const filters = {
        codeFilter: this.countryFiltersFormGroup.controls.code.value,
        countryCodeFilter: this.countryFiltersFormGroup.controls.country.value,
        codeDescriptionFilter:
          this.countryFiltersFormGroup.controls.description.value,
      };
      this.tableDataSource.data = [];
      this.countriesDataBE =
        await this.providerService.countryTableService.getTableData(
          this.startYear,
          this.endYear,
          this.sort.direction.toUpperCase() !== ''
            ? this.sort.active ?? 'code'
            : 'code',
          btoa(JSON.stringify(filters)),
          this.sort.direction.toUpperCase() !== ''
            ? this.sort.direction.toUpperCase()
            : 'ASC',
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      if (this.countriesDataBE.length !== 0) {
        this.buildArrayForYears(this.startYear, this.endYear);
        this.form = this._formBuilder.group({
          formGroups: this._formBuilder.array([]),
        });
        this.buildFormGroupsArrayDynamicallyUsingBEData();
        this.tableDataSource.data = (
          this.form.get('formGroups') as FormArray
        ).controls;
        await this.getTableCount();
      }
    } catch (e) {
      this.showTableError = true;
    } finally {
      this.loading = false;
    }
  }

  resizingChanged(event: boolean): void {
    this.resizing = event;
    this.table.updateStickyColumnStyles();
    this.changeDetectorRef.detectChanges();
  }

  async downloadShowedTableData(dialogRef: any, isRaw: boolean): Promise<any> {
    let dialog;
    try {
      this.downloadTableDataLoader = true;
      dialog = this.dialog.open(dialogRef, {
        width: '500px',
        disableClose: true,
        autoFocus: false,
      });
      const filters: any = {};

      // this.filters = {
      //   codeFilter: this.countryFiltersFormGroup.controls.code.value,
      //   countryCodeFilter: this.countryFiltersFormGroup.controls.country.value,
      //   codeDescriptionFilter:
      //     this.countryFiltersFormGroup.controls.description.value,
      // };

      const codeFilter = this.countryFiltersFormGroup.controls.code.value;
      if (codeFilter) {
        filters.codeFilter = codeFilter;
      }
      const descriptionFilter =
        this.countryFiltersFormGroup.controls.description.value;
      if (descriptionFilter) {
        filters.codeDescriptionFilter = descriptionFilter;
      }
      const countryNameFilter =
        this.countryFiltersFormGroup.controls.country.value;
      if (countryNameFilter) {
        filters.countryCodeFilter = countryNameFilter;
      }
      console.log(filters);
      const downloadDataParams: any = {
        filters: Object.keys(filters).length
          ? btoa(JSON.stringify(filters))
          : '',
      };
      downloadDataParams.fromYear = this.startYear.toString();
      downloadDataParams.toYear = this.endYear.toString();
      console.log(downloadDataParams);
      let result;
      if (isRaw) {
        result =
          await this.providerService.downloadDataService.downloadRawTableData(
            downloadDataParams
          );
      } else {
        downloadDataParams.sortCol = 'code';
        downloadDataParams.sortType = 'ASC';
        result =
          await this.providerService.downloadDataService.downloadTableData(
            downloadDataParams
          );
      }
      dialog.close();
      if (this.downloadTableDataLoader) {
        const link = document.createElement('a');
        link.href = result;
        link.setAttribute('download', 'raw-data.csv');
        link.click();
        link.remove();
      }
    } catch (e) {
      dialog?.close();
      this.snackBar.open('An error occured while downloading the file', 'X', {
        duration: 30000000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.downloadTableDataLoader = false;
    }
  }

  cancelDownload(): void {
    this.downloadTableDataLoader = false;
  }

  private setFormScroll(): void {
    this.formRef.nativeElement.scrollLeft =
      this.formRef.nativeElement.scrollWidth -
      this.formRef.nativeElement.clientWidth;
  }

  private _filter(value: any): void {
    if (typeof value === 'string' && value.length > 0) {
      const toSearch = value.toLowerCase();
      this.filteredCountries = this.countries.filter(
        (country) =>
          country.isoCode.toLowerCase().includes(toSearch) ||
          country.shortName.toLowerCase().includes(toSearch)
      );
    } else {
      this.filteredCountries = this.countries;
    }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-module',
  templateUrl: './upload-module.component.html',
  styleUrl: './upload-module.component.scss',
})
export class UploadModuleComponent {
  files: File[] = [];

  onFileDropped(files: FileList): void {
    this.handleFileInput(files);
  }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    this.handleFileInput(files);
  }

  handleFileInput(files: FileList): void {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  removeFile(file: File): void {
    this.files = this.files.filter((f) => f !== file);
  }
}

<div class="download-page-container" [formGroup]="downloadForm">

  <mat-card appearance="outlined">
    <mat-card-content>

      <mat-form-field appearance="fill">
        <mat-label>Data Format</mat-label>
        <mat-select formControlName="dataFormat">
          <mat-option value="rawData">Raw Data</mat-option>
          <mat-option value="yearsAsColumns">Years as Columns</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="year-container">
        <mat-form-field appearance="fill">
          <mat-label>From Year</mat-label>
          <input matInput type="number" formControlName="fromYear" placeholder="YYYY">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>To Year</mat-label>
          <input matInput type="number" formControlName="toYear" placeholder="YYYY">
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>Country Name</mat-label>
        <input matInput type="text" formControlName="countryNameFilter"
               placeholder="Enter country name filter" [matAutocomplete]="auto"/>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
          @for (country of filteredCountries; track country) {
            <mat-option class="autocomplete-mat-option" [value]="country.isoCode">
              {{ country.shortName }}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Code</mat-label>
        <input matInput type="text" formControlName="codeFilter" placeholder="Enter code filter">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <input matInput type="text" formControlName="descriptionFilter" placeholder="Enter description filter">
      </mat-form-field>

      <div *ngIf="showSortOptions" class="sort-options">
        <mat-form-field appearance="fill">
          <mat-label>Sort Column</mat-label>
          <mat-select formControlName="sortColumn">
            <mat-option value="code">Code</mat-option>
            <mat-option value="description">Description</mat-option>
            <mat-option value="countryName">Country Name</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Sort Type</mat-label>
          <mat-select formControlName="sortType">
            <mat-option value="ASC">Ascending</mat-option>
            <mat-option value="DESC">Descending</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="download-button">
        <button mat-raised-button (click)="downloadData(downloadDialog)"
                onclick="this.blur()" color="primary">
          <span class="download-raw-data-button">Download</span>
        </button>
      </div>

    </mat-card-content>
  </mat-card>

</div>

<ng-template #downloadDialog>
  <h2 mat-dialog-title class="mat-dialog-title">{{ 'DIALOG.DOWNLOAD_TITLE' | translate }}</h2>
  <mat-dialog-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p>{{ 'DIALOG.DOWNLOAD_MESSAGE' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close (click)="cancelDownload()">{{ 'DIALOG.CANCEL' | translate }}</button>
  </mat-dialog-actions>
</ng-template>

import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum ConditionalFormattingOperation {
  GREATER = 'CONDITIONAL_FORMATTING.GREATER',
  GREATER_OR_EQUAL = 'CONDITIONAL_FORMATTING.GREATER_OR_EQUAL',
  LESS = 'CONDITIONAL_FORMATTING.LESS',
  LESS_OR_EQUAL = 'CONDITIONAL_FORMATTING.LESS_OR_EQUAL',
  EQUAL = 'CONDITIONAL_FORMATTING.EQUAL',
  NOT_EQUAL = 'CONDITIONAL_FORMATTING.NOT_EQUAL',
}

@Component({
  selector: 'app-conditional-formatting',
  templateUrl: './conditional-formatting.component.html',
  styleUrl: './conditional-formatting.component.scss',
})
export class ConditionalFormattingComponent {
  eConditionalFormattingOperations = ConditionalFormattingOperation;
  formGroup!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConditionalFormattingComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!data) {
      this.formGroup = this.formBuilder.group({
        conditionsArray: this.formBuilder.array([this.createConditionGroup()]),
      });
    }

    if (data) {
      console.log(data);
      this.formGroup = this.formBuilder.group({
        conditionsArray: this.formBuilder.array([]),
      });
      const conditionsArray = this.formGroup.get(
        'conditionsArray'
      ) as FormArray;
      data.forEach((item: any) => {
        conditionsArray.push(
          this.createConditionGroupWithValues(
            item.conditionOperation,
            item.conditionThresholdValue
          )
        );
      });
    }
    dialogRef.disableClose = true;
  }

  createConditionGroup(): FormGroup {
    return this.formBuilder.group({
      conditionOperation: ['', Validators.required],
      inputValue: ['', Validators.required],
    });
  }

  createConditionGroupWithValues(operation: string, value: any): FormGroup {
    return this.formBuilder.group({
      conditionOperation: [operation, Validators.required],
      inputValue: [value, Validators.required],
    });
  }

  get conditionsArray(): FormArray {
    return this.formGroup.get('conditionsArray') as FormArray;
  }

  removeFormFieldRow(index: number): void {
    this.conditionsArray.removeAt(index);
  }

  addCondition(): void {
    this.conditionsArray.push(this.createConditionGroup());
  }

  applyFilter(): void {
    this.dialogRef.close(this.formGroup.value.conditionsArray);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProviderService } from '../../core/provider.service';
import { CountriesListsModel } from '../update-country-table/models/country-filters-model';
import { distinctUntilChanged } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';

@Component({
  selector: 'app-download-module',
  templateUrl: './download-module.component.html',
  styleUrls: ['./download-module.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class DownloadModuleComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  downloadForm!: FormGroup;
  showSortOptions = false;
  showError = false;
  downloadTableDataLoader = false;
  countries: CountriesListsModel[] = [{ isoCode: '', shortName: 'No Filter' }];
  filteredCountries: CountriesListsModel[] = [];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private providerService: ProviderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.downloadForm = this.fb.group({
      fromYear: ['', []],
      toYear: ['', []],
      codeFilter: [''],
      descriptionFilter: [''],
      countryNameFilter: [''],
      dataFormat: ['', Validators.required],
      sortColumn: [''],
      sortType: [''],
    });
    this.getCountriesList().then();
    this.downloadForm.get('dataFormat')?.valueChanges?.subscribe((value) => {
      this.onDataFormatChange(value);
    });
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.downloadForm.controls['countryNameFilter'].valueChanges
        .pipe(distinctUntilChanged(), debounceTime(50))
        .subscribe((countryCode) => {
          this._filter(countryCode);
        })
    );
  }

  onDataFormatChange(value: string): void {
    this.downloadForm.markAsUntouched();
    const fromYearControl = this.downloadForm.get('fromYear');
    const toYearControl = this.downloadForm.get('toYear');
    const sortColumnControl = this.downloadForm.get('sortColumn');
    const sortTypeControl = this.downloadForm.get('sortType');

    if (value === 'yearsAsColumns') {
      fromYearControl?.setValidators([
        Validators.required,
        Validators.min(1900),
        Validators.max(new Date().getFullYear()),
      ]);
      toYearControl?.setValidators([
        Validators.required,
        Validators.min(1900),
        Validators.max(new Date().getFullYear()),
      ]);
      sortColumnControl?.setValidators([Validators.required]);
      sortTypeControl?.setValidators([Validators.required]);
    } else {
      fromYearControl?.clearValidators();
      toYearControl?.clearValidators();
      sortColumnControl?.clearValidators();
      sortTypeControl?.clearValidators();
    }

    fromYearControl?.updateValueAndValidity();
    toYearControl?.updateValueAndValidity();
    sortColumnControl?.updateValueAndValidity();
    sortTypeControl?.updateValueAndValidity();

    this.showSortOptions = value === 'yearsAsColumns';
  }

  async downloadData(dialogRef: any): Promise<void> {
    this.downloadForm.markAllAsTouched();
    if (this.downloadForm.valid) {
      const filters: any = {};
      const codeFilter = this.downloadForm.get('codeFilter')?.value;
      if (codeFilter) {
        filters.codeFilter = codeFilter;
      }
      const descriptionFilter =
        this.downloadForm.get('descriptionFilter')?.value;
      if (descriptionFilter) {
        filters.codeDescriptionFilter = descriptionFilter;
      }
      const countryNameFilter =
        this.downloadForm.get('countryNameFilter')?.value;
      if (countryNameFilter) {
        filters.countryCodeFilter = countryNameFilter;
      }
      const downloadDataParams: any = {
        filters: Object.keys(filters).length
          ? btoa(JSON.stringify(filters))
          : '',
      };
      const fromYear = this.downloadForm.get('fromYear')?.value;
      if (fromYear) {
        downloadDataParams.fromYear = fromYear.toString();
      }
      const toYear = this.downloadForm.get('toYear')?.value;
      if (toYear) {
        downloadDataParams.toYear = toYear.toString();
      }
      const dataFormat = this.downloadForm.get('dataFormat')?.value;
      if (dataFormat === 'yearsAsColumns') {
        const sortCol = this.downloadForm.get('sortColumn')?.value;
        if (sortCol) {
          downloadDataParams.sortCol = sortCol;
        }
        const sortType = this.downloadForm.get('sortType')?.value;
        if (sortType) {
          downloadDataParams.sortType = sortType;
        }
      }
      console.log(downloadDataParams);
      let dialog;
      try {
        this.downloadTableDataLoader = true;
        dialog = this.dialog.open(dialogRef, {
          width: '500px',
          disableClose: true,
          autoFocus: false,
        });
        let result;
        if (dataFormat === 'yearsAsColumns') {
          result =
            await this.providerService.downloadDataService.downloadTableData(
              downloadDataParams
            );
        } else if (dataFormat === 'rawData') {
          result =
            await this.providerService.downloadDataService.downloadRawTableData(
              downloadDataParams
            );
        }
        dialog.close();
        if (this.downloadTableDataLoader) {
          const link = document.createElement('a');
          link.href = result;
          link.setAttribute('download', 'raw-data.csv');
          link.click();
          link.remove();
        }
      } catch (e) {
        dialog?.close();
        this.snackBar.open('An error occured while downloading the file', 'X', {
          duration: 3000,
          panelClass: ['error-snackbar'],
        });
        this.showError = true;
      } finally {
        this.downloadTableDataLoader = false;
      }
    }
  }

  async getCountriesList(): Promise<void> {
    try {
      this.countries = this.countries.concat(
        await this.providerService.countryTableService.getCountriesList()
      );
      this.filteredCountries = this.countries;
    } catch (error) {
      console.log(error);
    }
  }

  displayFn(value: any): string {
    return value
      ? this.filteredCountries.find((country) => country.isoCode === value)
          ?.shortName ?? ''
      : 'No filter';
  }

  cancelDownload(): void {
    this.downloadTableDataLoader = false;
  }

  private _filter(value: any): void {
    if (typeof value === 'string' && value.length > 0) {
      const toSearch = value.toLowerCase();
      this.filteredCountries = this.countries.filter(
        (country) =>
          country.isoCode.toLowerCase().includes(toSearch) ||
          country.shortName.toLowerCase().includes(toSearch)
      );
    } else {
      this.filteredCountries = this.countries;
    }
  }
}

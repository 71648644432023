// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const environment = {
  identityPoolId: 'eu-central-1:86f51f80-9f65-4a14-971d-f3d36ab53e18',
  backend: {
    endpoint: 'https://q2iepdmdt6.execute-api.eu-central-1.amazonaws.com/prod',
  },
  cognito: {
    userPoolId: 'eu-central-1_OVUoCE9ZO',
    userPoolWebClientId: '39vb2aqe6d8os06qs4973o7eht',
  },
};

export { environment };

import { IMapper } from '../../../models/i-mapper';
export class RawDataMapper implements IMapper {
  preSignedUrl = '';
  fillFromJson(json: any): void {
    this.preSignedUrl = json['preSignedUrl'];
  }
  fillToJson(): string {
    throw new Error('Method not implemented.');
  }
}

<div class="upload-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>File Upload</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="upload-drop-zone" appDragDrop (fileDropped)="onFileDropped($event)">
        <p>Drag and drop your files here</p>
        <i class="fas fa-cloud-upload-alt upload-icon"></i>
      </div>
      <input type="file" multiple (change)="onFileSelected($event)" hidden #fileInput>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="fileInput.click()">Select Files</button>
    </mat-card-actions>
  </mat-card>
  <div *ngIf="files.length > 0">
    <h3>Selected Files</h3>
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        {{ file.name }} ({{ file.size | fileSizePipe }})
        <button mat-icon-button (click)="removeFile(file)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </div>
</div>

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProviderService } from '../../core/provider.service';
import { RegionsListsModel } from './models/region-filters-model';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { RegionDetailsModel } from './models/region-details-model';
import { MatPaginator } from '@angular/material/paginator';
import { ClassModel } from './models/class-model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-regions-management',
  templateUrl: './regions-management.component.html',
  styleUrl: './regions-management.component.scss',
})
export class RegionsManagementComponent implements OnInit {
  filterRegionFormGroup = new FormGroup({
    region: new FormControl(''),
    regionCode: new FormControl(''),
    classification: new FormControl(''),
    parentRegion: new FormControl(''),
  });
  regions: RegionsListsModel[] = [];
  classes: ClassModel[] = [];
  regionDetails!: RegionDetailsModel;
  dataSource!: MatTableDataSource<RegionsListsModel>;
  displayedColumns = [
    'regionCode',
    'regionName',
    'classification',
    'classificationDescription',
    'parentRegion',
  ];
  loading = false;
  detailOpen = false;
  createRegionDialog: any;
  deleteRegionDialog: any;
  createRegionFormGroup = new FormGroup({
    regionName: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
    regionCode: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
    regionParent: new FormControl(''),
    regionClass: new FormControl(''),
    copyFrom: new FormControl(''),
    newClassName: new FormControl(''),
    newClassCode: new FormControl(''),
    newClassDescription: new FormControl(''),
    autocompleteRegion: new FormControl(''),
    autocompleteClass: new FormControl(''),
    autocompleteCopyFrom: new FormControl(''),
  });
  @ViewChild('regionParentElement')
  regionParentElement!: ElementRef;
  @ViewChild('regionClassElement')
  regionClassElement!: ElementRef;
  @ViewChild('copyFromElement')
  copyFromElement!: ElementRef;
  isCreatingNewClass = false;
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
      this.dataSource.sortingDataAccessor = (item: any, property: any) => {
        const columnName = property;
        if (item[columnName]) {
          return item[columnName].toString().toLowerCase();
        } else if (
          columnName === 'classification' ||
          columnName === 'parentRegion'
        ) {
          if (item[columnName + 'Name']) {
            return item[columnName + 'Name'].toString().toLowerCase();
          } else return '-';
        }
      };
    }
  }
  subscription: any;
  selectedCopyfrom = true;
  manuallySelect = true;

  constructor(
    private dialog: MatDialog,
    private providerService: ProviderService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource<RegionsListsModel>([]);
  }

  async ngOnInit(): Promise<void> {
    this.subscription = this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          if (event.url === '/app/data-management-regions') {
            this.detailOpen = false;
            this.loading = true;
            await this.getRegionAndClasses();
            this.loading = false;
          } else if (event.url.includes('?regionID')) {
            const params = new URLSearchParams(event.url.split('?')[1]);
            const regionId = params.get('regionID') || '';
            await this.goToDetailsWithRegionId(regionId);
          }
        }
      }
    });
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams['regionID']) {
      await this.goToDetailsWithRegionId(queryParams['regionID']);
    } else {
      this.loading = true;
      await this.getRegionAndClasses();
      this.loading = false;
    }
  }

  async goToDetailsWithRegionId(regionId: string): Promise<void> {
    try {
      this.loading = true;
      await this.getRegionAndClasses();
      this.regionDetails =
        await this.providerService.regionsManagementService.getRegionDetails(
          regionId
        );
      this.detailOpen = true;
    } catch (e) {
      this.removeQueryParam('regionID');
    } finally {
      this.loading = false;
    }
  }

  async getRegionAndClasses(): Promise<void> {
    this.regions =
      await this.providerService.regionsManagementService.getRegionsList();
    this.classes =
      await this.providerService.regionsManagementService.getClassList();
    this.regions = this.regions.map((r) => {
      return {
        ...r,
        classificationDescription: this.classes.find(
          (c) => c.classID === r.classificationID
        )?.classDescription,
      };
    });
    this.dataSource.data = this.regions;
  }

  removeQueryParam(param: string): void {
    const queryParams = { ...this.route.snapshot.queryParams };
    delete queryParams[param];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      queryParamsHandling: 'replace',
    });
  }

  applyFilter(): void {
    const filtersValue = {
      region: this.filterRegionFormGroup.controls.region.value?.toLowerCase(),
      regionCode:
        this.filterRegionFormGroup.controls.regionCode.value?.toLowerCase(),
      classification:
        this.filterRegionFormGroup.controls.classification.value?.toLowerCase(),
      parentRegion:
        this.filterRegionFormGroup.controls.parentRegion.value?.toLowerCase(),
    };
    this.dataSource.filterPredicate = (data, filterString: any) => {
      const filters = JSON.parse(filterString);
      return (
        data.regionCode.toLowerCase().includes(filters.regionCode) &&
        data.regionName.toLowerCase().includes(filters.region) &&
        (data.classificationName || '-')
          .toLowerCase()
          .includes(filters.classification) &&
        (data.parentRegionName || '-')
          .toLowerCase()
          .includes(filters.parentRegion)
      );
    };
    this.dataSource.filter = JSON.stringify(filtersValue);
  }

  async openDetail(regionId: string): Promise<void> {
    this.loading = true;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { regionID: regionId },
      queryParamsHandling: 'merge',
    });
    this.regionDetails =
      await this.providerService.regionsManagementService.getRegionDetails(
        regionId
      );
    this.loading = false;
    this.detailOpen = true;
  }

  onLoadingDetailsChange(event: boolean): void {
    this.loading = event;
    this.cdr.detectChanges();
  }

  onDetailsChanged(event: RegionDetailsModel): void {
    this.regionDetails = event;
    this.cdr.detectChanges();
  }

  async openCreateRegionDialog(dialogRef: any): Promise<any> {
    this.createRegionDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
      data: { regions: this.regions, classes: this.classes },
    });
  }

  onOpenedRegionChange(isOpened: boolean): void {
    if (isOpened) {
      this.regionParentElement.nativeElement.focus();
    }
  }

  onOpenedClassChange(isOpened: boolean): void {
    if (isOpened) {
      this.regionClassElement.nativeElement.focus();
    }
  }

  onOpenedCopyFromChange(isOpened: boolean): void {
    if (isOpened) {
      this.copyFromElement.nativeElement.focus();
    }
  }

  visuallyFilterRegion(region: string): boolean {
    const regionName = region.toLowerCase();
    const input = (
      this.createRegionFormGroup.controls.autocompleteRegion.value || ''
    ).toLowerCase();
    return input === '' || regionName.includes(input);
  }

  visuallyFilterRegionCopyFrom(region: string): boolean {
    const regionName = region.toLowerCase();
    const input = (
      this.createRegionFormGroup.controls.autocompleteCopyFrom.value || ''
    ).toLowerCase();
    return input === '' || regionName.includes(input);
  }

  visuallyFilterClass(cl: string): boolean {
    const className = cl.toLowerCase();
    const input = (
      this.createRegionFormGroup.controls.autocompleteClass.value || ''
    ).toLowerCase();
    return input === '' || className.includes(input);
  }

  removeParentRegion(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.createRegionFormGroup.controls.regionParent.setValue('');
    this.createRegionFormGroup.controls.autocompleteRegion.setValue('');
    this.regionParentElement.nativeElement.focus();
  }

  removeRegionClass(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.isCreatingNewClass = false;
    this.createRegionFormGroup.controls.newClassName.clearValidators();
    this.createRegionFormGroup.controls.newClassCode.clearValidators();
    this.createRegionFormGroup.controls.newClassName.updateValueAndValidity();
    this.createRegionFormGroup.controls.regionClass.setValue('');
    this.createRegionFormGroup.controls.autocompleteClass.setValue('');
    this.regionClassElement.nativeElement.focus();
  }

  removeCopyFrom(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.selectedCopyfrom = true;
    this.manuallySelect = true;
    this.createRegionFormGroup.controls.copyFrom.setValue('');
    this.createRegionFormGroup.controls.autocompleteCopyFrom.setValue('');
    this.copyFromElement.nativeElement.focus();
  }

  reset(): void {
    this.createRegionFormGroup.controls.regionName.setValue('');
    this.createRegionFormGroup.controls.regionCode.setValue('');
    this.createRegionFormGroup.controls.regionClass.setValue('');
    this.createRegionFormGroup.controls.regionParent.setValue('');
    this.createRegionFormGroup.controls.copyFrom.setValue('');
    this.createRegionFormGroup.controls.regionName.markAsUntouched();
    this.createRegionFormGroup.controls.regionCode.markAsUntouched();
    this.isCreatingNewClass = false;
    this.manuallySelect = true;
    this.selectedCopyfrom = true;
  }

  async createRegion(): Promise<void> {
    this.loading = true;
    this.createRegionDialog?.close();
    try {
      if (this.isCreatingNewClass) {
        const newClassID =
          await this.providerService.regionsManagementService.createClassification(
            this.createRegionFormGroup.controls.newClassName.value || '',
            this.createRegionFormGroup.controls.newClassCode.value || '',
            this.createRegionFormGroup.controls.newClassDescription.value || ''
          );
        this.classes =
          await this.providerService.regionsManagementService.getClassList();
        this.createRegionFormGroup.controls.regionClass.setValue(
          newClassID || ''
        );
      }
      const regionName = this.createRegionFormGroup.controls.regionName.value;
      const regionCode = this.createRegionFormGroup.controls.regionCode.value;
      let regionParent;
      let regionClass;
      let years;
      if (this.selectedCopyfrom && !this.manuallySelect) {
        const copyFromRegionID =
          this.createRegionFormGroup.controls.copyFrom.value || '';
        const copyFromRegion = this.regions.find(
          (r) => r.regionID === copyFromRegionID
        );
        const regionYears =
          await this.providerService.regionsManagementService.getRegionDetails(
            copyFromRegionID
          );
        regionParent = copyFromRegion?.parentRegionID;
        regionClass = copyFromRegion?.classificationID;
        years = regionYears.years;
      } else {
        regionParent = this.createRegionFormGroup.controls.regionParent?.value;
        regionClass = this.createRegionFormGroup.controls.regionClass?.value;
        years = undefined;
      }
      await this.providerService.regionsManagementService.createRegion(
        regionName,
        regionCode,
        regionParent,
        regionClass,
        years
      );
      this.regions =
        await this.providerService.regionsManagementService.getRegionsList();
      this.dataSource.data = this.regions;
    } catch (e) {
      this.snackBar.open('An error occured while creating the region', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.reset();
      this.loading = false;
    }
  }

  displayClass(classId: string): string {
    if (classId === 'new-class') return 'Create New Class';
    return this.classes.find((c) => c.classID === classId)?.className || '';
  }

  displayRegion(reg: string): string {
    return this.regions.find((c) => c.regionID === reg)?.regionName || '';
  }

  openDeleteRegionConfirmDialog(dialogRef: any): void {
    this.deleteRegionDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async deleteRegion(): Promise<void> {
    this.deleteRegionDialog?.close();
    this.detailOpen = false;
    this.loading = true;
    try {
      const regionID = this.regionDetails.regionID;
      await this.providerService.regionsManagementService.deleteRegion(
        regionID
      );
      this.regions =
        await this.providerService.regionsManagementService.getRegionsList();
      this.dataSource.data = this.regions;
    } catch (e) {
      this.snackBar.open('An error occured while deleting the region', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.loading = false;
    }
  }

  async goBack(): Promise<void> {
    this.detailOpen = false;
    this.loading = true;
    this.regions =
      await this.providerService.regionsManagementService.getRegionsList();
    this.dataSource.data = this.regions;
    this.loading = false;
    this.removeQueryParam('regionID');
  }

  selectCopyChangeRegion(): void {
    this.selectedCopyfrom = true;
    this.manuallySelect = false;
  }

  setupForNewClass(): void {
    this.isCreatingNewClass = true;
    this.createRegionFormGroup.controls.newClassName.setValidators([
      Validators.required,
      Validators.maxLength(255),
    ]);
    this.createRegionFormGroup.controls.newClassCode.setValidators([
      Validators.required,
      Validators.maxLength(20),
    ]);
    this.createRegionFormGroup.controls.newClassName.updateValueAndValidity();
  }
}

import { Injectable } from '@angular/core';
import { IAuthService } from './auth-utils/i-auth-service';
import { CognitoService } from '../components/auth/cognito.service';
import { UtilsService } from './app-utils/utils/utils.service';
import { NetworkService } from './net-utils/network.service';
import { IamAuthenticatorService } from './net-utils/iam-authenticator.service';
import { HomeService } from '../components/home/home.service';
import { UpdateCountryTableService } from '../components/update-country-table/service/update-country-table-service';
import { ModifyOneRowService } from '../components/dialogues/modify-one-row/modify-one-row-service';
import { DownloadDataService } from '../components/update-country-table/service/download-data.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  constructor(
    private utilsService: UtilsService,
    private authServiceInstance: CognitoService,
    private netService: NetworkService,
    private iamAuthenticatorService: IamAuthenticatorService,
    private homeServiceInstance: HomeService,
    private updateCountryTableServiceInstance: UpdateCountryTableService,
    private modifyOneRowServiceInstance: ModifyOneRowService,
    private downloadDataServiceInstance: DownloadDataService
  ) {}

  get authService(): IAuthService {
    return this.authServiceInstance;
  }

  get countryTableService(): UpdateCountryTableService {
    this.updateCountryTableServiceInstance.setNetworkService(
      this.networkService
    );
    return this.updateCountryTableServiceInstance;
  }
  get utilService(): UtilsService {
    return this.utilsService;
  }
  get modifyOneRowService(): ModifyOneRowService {
    this.modifyOneRowServiceInstance.getNetwork(this.networkService);
    return this.modifyOneRowServiceInstance;
  }
  get homeService(): HomeService {
    return this.homeServiceInstance;
  }

  get networkService(): NetworkService {
    this.netService.setAuthenticator(this.iamAuthenticatorService);
    return this.netService;
  }

  get downloadDataService(): DownloadDataService {
    this.downloadDataServiceInstance.setNetworkService(this.networkService);
    return this.downloadDataServiceInstance;
  }
}

import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CoreModule } from '../core/core.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { LoaderComponent } from './loader/loader.component';
import { AdminViewComponent } from './admin-section/admin-view/admin-view.component';
import { UpdateCountryTableComponent } from './update-country-table/update-country-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { ModifyOneRowComponent } from './dialogues/modify-one-row/modify-one-row.component';
import { DeleteRowErrorDialogComponent } from './dialogues/delete-row-error-dialog/delete-row-error-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ResizeColumnDirective } from './update-country-table/resize-column.directive';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ConditionalFormattingComponent } from './dialogues/conditional-formatting/conditional-formatting.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { DownloadModuleComponent } from './download-module/download-module.component';
import { UploadModuleComponent } from './upload-module/upload-module.component';
import { DragDropDirective } from './upload-module/utils/drag-drop.directive';
import { FileSizePipe } from './upload-module/utils/file-size.pipe';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [
    NotFoundComponent,
    HomeComponent,
    SignInComponent,
    SignUpComponent,
    NavbarComponent,
    LoaderComponent,
    AdminViewComponent,
    UpdateCountryTableComponent,
    ModifyOneRowComponent,
    DeleteRowErrorDialogComponent,
    ResizeColumnDirective,
    ConditionalFormattingComponent,
    DownloadModuleComponent,
    UploadModuleComponent,
    DragDropDirective,
    FileSizePipe,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    CoreModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatCardModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    TranslateModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSortModule,
    MatAutocompleteModule,
    MatProgressBar,
    MatListModule,
  ],
  exports: [NavbarComponent],
})
export class ComponentsModule {}
